.App {
  text-align: center;
}

.App-logo {
  height: 80vmin;
  pointer-events: none;
}

.App-header {
  background-color: #ffe3e5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.theCanvas{
  position:fixed;
  top:0;
  right:0;
  bottom:0;
  left:0;
}

.hide {
  opacity: 0;
  transition: opacity 1s;
}

.invisible {
  opacity: 0;
}

.visible {
  opacity: 1;
  transition: opacity 5s;
}

.linky {
  color: rgb(85, 26, 139)
}

a {
  color: rgb(85, 26, 139)
}